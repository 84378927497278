import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function DigitalSignagePage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Digital Signage Technologies and Systems"
        description="Digital Signage, being highly customizable, is at the forefront of outdoor advertisement. WYN technologies provide Digital Signage solutions in all configurations. Get your quote today."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Digital Signage Solutions</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Digital Signage Solutions</GroceryTitle>
              <p>Digital signage may be an overarching term that’s used to describe any form of digital advertisement, but we can’t deny that it’s an important facet of our everyday lives. Each time you drive down the road, you’ll come face to face with some form of digital signage, whether it’s a restaurant menu, hospital data monitors, retail advertisements, or even highway signs. To simplify, digital signage can consist of any size digital screen that displays digital content of any kind.</p>
              <p>Digital signage is an excellent way for any business to advertise, inform and entertain its customers or employees. WYN Technologies specializes in the installation of digital signage and is proud to offer a wide range of configurations and options to fit your business’ needs.</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Provide with Our Digital Signage Setup & Installation Services</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Product Orientation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p><strong>WYN</strong> is happy to serve as a preferred provider of top digital signage products from the best brands, including Samsung, Sharp, and Planar. And we’re not just a vendor of these products, either. WYN provides full-service signage installation, and we have many satisfied clients who can testify to the quality of our installation workmanship. After the equipment installation is finished, we still aren’t done. We’ll walk you through a proper product orientation, and we’ll make sure you have a good understanding of how everything works. We’ll be sure to answer all questions you may have, too!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Site Surveys</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Before <strong>WYN</strong> ever recommends a particular digital signage solution, we’ll start by taking the time to listen to you and learn more about your needs and goals. We’ll also want to have one or more of our crackerjack technicians come out and do an in-person, physical survey of your business or intended signage installation sites. After our walkthrough, we’ll share our insights about leveraging digital signage for your facility based upon our extensive professional experience. Finally, we’ll give some recommendations about the best digital signage solutions to meet your particular needs, as well as to suit your budget.</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Free Product Demos </GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>It’s entirely possible that you’ve seen digital signs in other businesses or locations but have never had the opportunity to examine one up-close and personal. <strong>WYN</strong> can help with that! If you’re new to shopping for digital signage or would just like to learn more about the particular features and benefits of the products, we can install them for you. We’re happy to let you experience a product demo for yourself. We’ll show you what a programmable digital sign can do, and we’ll also illuminate how you can take full advantage. To that end, we’re really here to help your business!</p>
               {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Ongoing Support</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Sometimes other business technology vendors or installation services leave you feeling somewhat high-and-dry after your equipment is put in. Well, that’s not how we operate at <strong>WYN Technologies</strong>! We’re happy to offer you a “service after the sale” contract to help keep everything maintained and working well for you in the future. </p>
                <p>And in any case, we’ll be here for you any time you may need us. Got a problem or some issue with your digital signage? Just give us a call, and we’ll be prompt to help you in any way we can!</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle>
              <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
              <MarkdownContent>
                <h2>Digital Signage Technology</h2>
                <p>Digital signage systems have come a long way since the simplistic dot matrix LED signs you’ll see hanging in a contemporary restaurant. In fact, present-day signage technology appears in many wildly varying forms and features a plethora of unique possibilities.</p>
                <ul>
                  <li>
                    <strong>Interactive</strong>
                    <p>Interactive signage are signs designed to interact with clients utilizing technology like mobile-based SMS, GPS, and Bluetooth commonly found in smartphones. Some are even engineered for social media and locational interactivity.</p>

                    <p>With the omnipresence of smartphones today, many digital signage companies are also moving to develop screen-smart device interaction capabilities.</p>
                  </li>
                  <li>
                    <strong>Context-aware Digital Signage</strong>
                    <p>This digital signage system uses cameras, sensors, RFID tech, and a host of other state-of-the-art technological advancements to deliver personalized promotions based on cues like gender, estimated age, and even economic status. And it isn’t stopping there! Artificial intelligence and even deep learning technologies are being merged with digital signage to maximize efficiency and provide more streamlined customer experiences.</p>
                  </li>
                  <li>
                    <strong>Equipment and Network Infrastructure</strong>
                    <p>Digital signs rely on numerous pieces of hardware to function, such as media players, display screens, and content management servers. These servers can support multiple interconnected devices across a network. A great example of this in action comes in the form of digital bulletin boards that broadcast across dozens of screens in larger hospitals. In addition to these systems, there are also standalone options, with their own dedicated players requiring no network connections.</p>
                  </li>
                  <li>
                    <strong>LCD and LED Displays</strong>
                    <p>Falling prices for larger LCD and plasma screens have provided a huge boost to the number of digital signage systems in the market today. Liquid Crystal Displays (LCD) screens feature a thin layer of liquid suspended between two pieces of polarized glass backlit by small fluorescent lamps, while LED, or Light Emitting Diodes, are screens where backlit is produced with LED lights. Each of these two types has their own advantages and disadvantages when it comes to being used in digital signage, but both have become cost-effective solutions.</p>
                  </li>
                  <li>
                    <strong>Usage Reporting</strong>
                    <p>Some digital signage systems can provide interactive media content while collecting usage reporting data on the back end. Each time a customer or client interacts with the signage, it will gauge advertisement effectiveness and report back customizable advertising data. Some of these technologies are so sophisticated that they can measure key demographic details like gender, age and personalized consumer data.</p>
                  </li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Industry Usage and Digital Signage</h2>
                <p>Digital signage advertising is practically everywhere in our daily lives, with no signs of the technology slowing. In fact, there are now hundreds of companies worldwide manufacturing digital signage solutions, with that number steadily on the rise. As a result, it’s hard to go anywhere without coming across digital signage in some shape or form. They can be found in:</p>
                <ul>
                  <li>
                    <strong>Restaurants</strong>
                    <p>Restaurants often have digital menus that rotate and display alternating offers and products for customers. And it’s not just indoors! Outdoor signage is effective in drive-throughs, allowing customers to see entire menus at a glance. Interactive digital kiosks are also quickly becoming the norm in larger franchises, giving clients the ability to manage their orders without consulting with an employee. These kiosks are tremendously improving efficiency and profits in the service sectors.</p>
                  </li>
                  <li>
                    <strong>Shopping Malls</strong>
                    <p>You won’t ever be able to walk through a shopping mall these days without being inundated with digital signage. For years this technology has been widely used by malls and larger retail establishments for store signs, maps, directories, and even interactive advertisements that allow customers to see relevant deals and essential information. They can also be helpful for employees, giving them the power to track live inventories and many other forms of data.</p>
                  </li>
                  <li>
                    <strong>Hospitality</strong>
                    <p>No more ringing that bell over and over, hoping the front desk clerk will show up! The hospitality sector increasingly employs digital signage to display live hotel rates, pertinent guest information, and even interactive concierge services. Customers can use this technology to quickly search for local tourist attractions, restaurants, and transportation information, all from one interactive kiosk</p>
                  </li>
                  <li>
                    <strong>Cinema</strong>
                    <p>Digital signage advertising can be especially useful in cinemas for interactive lobby kiosks, broadcasting movie trailers and previews, posters and advertisements, concession services, live seating inventory, and even adjustable ticket prices. Gone are the days of the young movie attendant clumsily changing out those little black letters on the theater marquee! Now updating movie selections and their signage will only take a click of the mouse.</p>
                  </li>
                  <li>
                    <strong>Transportation</strong>
                    <p>The transportation sector is also seeing a digital signage takeover. It’s already present in interstate road signage, giving transportation departments the capacity to post updatable information like variable speed limits, road work, detours, and emergency information. For travelers, digital signage can show up in the form of interactive navigation stands and out-of-home advertising like billboards.</p>
                  </li>
                  <li>
                    <strong>Education</strong>
                    <p>There is no end to the possibilities for smart digital signage in the educational and administrative sectors. Digital signage is often used in these fields for interactive classroom environments, digital whiteboards, noticeboards, assemblies, emergency notifications, and campus wayfinding directories. These signage solutions are all-encompassing and range from giant school billboards down to interactive cafeteria menus and kiosks!</p>
                  </li>
                  <li>
                    <strong>And more!</strong>
                    <p>It’s no secret that digital signage is the future of advertising. Digital signage is pervasive, inhabiting almost every facet of our everyday lives. You already can’t turn around in public without being confronted by at least one digital marquee or advertisement. It’s even at the gas pumps!</p>
                    <p>And this technology will only continue to advance, building upon previous tech and computing breakthroughs to maximize interactivity, efficiency, and consumer data collection. Additionally, the advent of A.I. and deep neural learning technology will only serve to supercharge progress in digital signage capabilities.</p>
                  </li>
                </ul>


              </MarkdownContent>
              <MarkdownContent>
                <h2>Finding a Digital Signage Solution</h2>
                <p>The business world is embracing the advantages larger scale signage can bring. But where would a business even start when figuring out the best fit for their digital signage needs? There are a few things to take into consideration:</p>
                <ul>
                  <li>What is your estimated budget?</li>
                  <li>How many displays will you need?</li>
                  <li>What type of content will you need to display?</li>
                </ul>
                <br/>
                <br/>
                <p>Once a business has clarified these needs, you will want to take note of other specifications like:</p>
                <ul>
                  <li>
                    <strong>Hardware</strong>
                    <p>Displays, wiring, mounts, and any other infrastructure you’ll need.</p>
                  </li>
                  <li>
                    <strong>Software</strong>
                    <p>Your displays will need software, including content management systems, to operate.</p>
                  </li>
                  <li>
                    <strong>Installation</strong>
                    <p>You will want to consult with an expert to determine what installation steps will be necessary, including setup costs.</p>
                  </li>
                  <li>
                    <strong>Network</strong>
                    <p>This factor will determine whether your displays are standalone or connected to a network via WiFi or hardlines.</p>
                  </li>
                  <li>
                    <strong>Digital Content</strong>
                    <p>You’ll need to have a solid grasp of exactly what content you plan to display on your digital signage.</p>
                  </li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>More on Software for Digital Signage</h2>
                <p>None of the previously noted hardware would work without solid software to back it up. Content Management Systems (CMS) are some of the best tools to organize and display your content. Alternatively, depending on your hardware, you may also have the added option to use media players.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Digital Signage with WYN Technologies</h2>
                <p>WYN Technologies specializes in the sale, installation, and support to meet all your digital signage needs. We can help you navigate the uncertainty of choosing the right digital signage system for your business. Whether you’re looking for larger-scale networked signage and content management systems, or smaller standalone signage, we will find the solution!</p>

                <p>And it doesn’t end with installation! We also specialize in providing the best support for your hardware and software! With WYN Technologies, we’ll be happy to offer contracts for ‘service after the sale’ so that you can rest assured that your technology is maintained and reliable when you need it!</p>
                <p>Give us a call today at <a href="tel:3368990555">(336) 899-0555</a>, and let our experts assist you!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default DigitalSignagePage
